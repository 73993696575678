<template>
  <div class="session session-news" v-loading="loading">
    <div class="content-inner">
      <div class="session-h1">{{category.name}}</div>
      <div class="session-h2">专业二手车交易运营服务商</div>

      <ul class="news-list">
        <li v-for="(article, articleIndex) in articles" :key="articleIndex">
          <div class="news-item-cover">
            <router-link :to="'/news/'+categoryId+'/'+article.id"><img width="308" height="215" :src="fileUrl+article.newsThumbnail"></router-link>
          </div>
          <div class="news-item-txt">
            <div class="news-item-txt-t"><router-link :to="'/news/'+categoryId+'/'+article.id">{{ article.newsTitle }}</router-link></div>
            <div class="news-item-txt-b">
              {{ article.newsAbstract }}
            </div>
            <div class="new-item-link"><router-link :to="'/news/'+categoryId+'/'+article.id">【详情】</router-link> </div>
            <div class="new-item-date">
              <!--<span class="item-click-count">699</span>-->
              <span class="item-date">{{ article.newsPublisherTime }}</span>
            </div>
          </div>
        </li>
        <li class="clear"></li>
      </ul>

      <pagination v-show="total>0" :total="total" :page.sync="listQuery.pageNum" :limit.sync="listQuery.pageSize" @pagination="getList" />

      <el-empty v-if="!articles.length" description="暂无内容"></el-empty>




    </div>
  </div>
</template>

<script>
import {getList} from "@/api/articles";
import {getCategory} from "@/api/category"
import env from "@/env"
import Pagination from '@/components/Pagination'

const fileUrl = env.fileUrl

export default {
  components: { Pagination },
  data() {
    return {
      loading: false,
      articles: [],
      fileUrl:fileUrl,
      categoryId:0,
      category:{
        namd:'',
      },
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 10,
      },
    };
  },
  mounted() {
    this.getList()
    this.getCategory()
  },
  methods: {
    getList(){
      this.loading = true
      this.categoryId = this.$route.params.id
      getList(this.categoryId, this.listQuery).then(response => {
        this.articles=response.data.list
        this.loading = false
        this.total = response.data.totalCount
      })
    },
    getCategory(){
      getCategory(this.$route.params.id).then(response => {
        this.category=response.data
      })
    },

  },
  watch:{
    $route(){
      this.getList()
    }
  }

};
</script>

<style lang="scss" scoped>
.page-banner{
  height: 520px;
  background-image: url("../../assets/images/banner-4.jpg");
}


</style>
